import $ from "jquery";


$(function() {
    // handle submit event of form

        function builderror(errors, $form){



            $('#'+$form.attr('id')+' #errors-list > ul').html('')
            $.each(errors, function (key, value) {
                if(key == "message"){
                    $('#'+$form.attr('id')+' #errors-list > ul').append($("<li>").text(value))
                }
            });


        }

        $(document).on("submit", "#registerform", function() {
            var e = this;
            // change login button text before ajax
            $(this).find("[type='submit']").prop( "disabled", true );

            let action = $(this).attr('action');
            $.post(action, $(this).serialize(), function(data) {

                window.location.reload()
            }).fail(function(response) {

                builderror($.parseJSON(response.responseText), $(e));
                $(e).find("[type='submit']").prop( "disabled", false );


                console.log(response);
                console.log($(e));
            });
        return false;
        });
});
