import dayjs from 'dayjs';
if(document.getElementById('app_feed') != null){

    document.addEventListener('alpine:init', () => {

        Alpine.data('app_feed', (route) =>({
            listfeed : [],
            encoursdelete : {
                'feed_id' : null,
                'pro_id' : null
            },
            showalertbottom : false,
            to_alert_botton : null,
            init(){
              this.loadfeed();
            },
            loadfeed() {
                axios({
                    method: 'get',
                    url: route,
                    data: {
                        filterStatus: 'active',
                      }
                }).then(response =>{
                    if(typeof response.data.feed != "undefined" && typeof response.data.pros  != "undefined"){

                        for (let i = 0; i < response.data.feed.length; i++) {

                            response.data.feed[i]['pro_data'] = [];
                            // on ajoute le pro au feed element
                            for (let j = 0; j < response.data.pros.length; j++) {
                                if(response.data.feed[i].pro_id == response.data.pros[j].id){
                                    response.data.feed[i]['pro_data'] = response.data.pros[j];
                                }
                            }

                            //dates
                            response.data.feed[i]['open_at_short']= dayjs(response.data.feed[i]['open_at']).format('DD MMM YYYY');
                            response.data.feed[i]['open_at_long']= dayjs(response.data.feed[i]['open_at']).format('DD MMM YYYY HH:mm');

                            //traitement text
                            if(response.data.feed[i]['description'] != null){
                                response.data.feed[i]['description_tab'] = response.data.feed[i]['description'].split(/(?:\r\n|\r|\n)/g);
                                for (let idesc = 0; idesc < response.data.feed[i]['description_tab'].length; idesc++) {
                                    if(response.data.feed[i]['description_tab'][idesc]  == ""){
                                        response.data.feed[i]['description_tab'][idesc] = false;
                                    }
                                }
                            }

                            //image
                            response.data.feed[i]['nb_thumbnail'] = response.data.feed[i].images.length;

                            //Est ce qu'on a des droits ?
                            if(response.data.myteam.includes(response.data.feed[i].pro_id)){
                                response.data.feed[i]['is_admin'] = true;
                            }else{
                                response.data.feed[i]['is_admin'] = false;
                            }

                            response.data.feed[i]['url_feed'] = response.data.domain+'/pro/'+response.data.feed[i].pro_data.nom_uri+'/feed/'+response.data.feed[i].id;


                        }

                        this.listfeed = response.data.feed;
                    }

                });
            },
            deletefeed(feed_id, pro_id){

                axios({
                    method: 'delete',
                    url: `/dashboard/${this.encoursdelete.pro_id}/feed/${this.encoursdelete.feed_id}`,
                    data: {
                        feed_id: this.encoursdelete.feed_id,
                        pro_id: this.encoursdelete.pro_id
                      }
                }).then(response =>{
                    console.log(response);
                    document.getElementById('app_feed').dispatchEvent(new CustomEvent('refreshfeed', { detail: {}}))

                    this.showalertbottom = true;
                    this.to_alert_botton = setTimeout(  () => {
                        this.showalertbottom = false
                    }, 3000);
                    this.canceldeletefeed();
                })
            },
            opendeletefeed(feed_id, pro_id){
                this.encoursdelete.feed_id = feed_id;
                this.encoursdelete.pro_id = pro_id;
                document.getElementById('modal-deletefeed').checked = true;

            },
            canceldeletefeed(){
                document.getElementById('modal-deletefeed').checked = false;
                this.encoursdelete.feed_id = null;
                this.encoursdelete.pro_id = null;


            }
        }))
    })

}
