import $ from "jquery";


$(function() {
    // handle submit event of form

        function builderror(errors, $form){



            $('#'+$form.attr('id')+' #errors-list > ul').html('')
            $.each(errors, function (key, value) {
                if(key == "message"){
                    $('#'+$form.attr('id')+' #errors-list > ul').append($("<li>").text(value))
                }
            });

            /*
            console.log(errors);
            const $ul = $('<ul>', { class: "mt-3 list-disc list-inside text-sm text-red-600" }).append(
                errors.map(e =>
                  $("<li>").append($("<span>").text(e))
                )

                $.each(errors => (index, value) {
                    if(index == "message"){

                    }
                    console.log(value)
                 });
              );*/
        }

        $(document).on("submit", "#loginform", function() {
            var e = this;
            // change login button text before ajax
            $(this).find("[type='submit']").prop( "disabled", true );

            $.post($(this).attr('action'), $(this).serialize(), function(data) {

                window.location.reload()
            }).fail(function(response) {

                builderror($.parseJSON(response.responseText), $(e));
                $(e).find("[type='submit']").prop( "disabled", false );


                console.log(response);
                console.log($(e));
            });
        return false;
        });
});
