import './bootstrap';

import Alpine from 'alpinejs';
import dayjs from 'dayjs';
require('dayjs/locale/fr')
dayjs.locale('fr')

window.Alpine = Alpine;

Alpine.start();

import { toggleModal } from './layout/modal';
window.toggleModal = toggleModal;

import './layout/loginform.js';
import './layout/registerform.js';

import './layout/auth/feed_list';
