var openmodal = document.querySelectorAll('.modal-open')
for (var i = 0; i < openmodal.length; i++) {
    openmodal[i].addEventListener('click', function(event){
    event.preventDefault()
    toggleModal()
    })
}

const overlay = document.querySelectorAll('.modal-overlay')

for (var i = 0; i < overlay.length; i++) {
    overlay[i].addEventListener('click', function(){
        let modal =  document.querySelector('.modal.isdisplay');
        toggleModal(modal.getAttribute('data-identifier'));
    })
}

var closemodal = document.querySelectorAll('.modal-close')
for (var i = 0; i < closemodal.length; i++) {
    closemodal[i].addEventListener('click', function(){
        let modal =  document.querySelector('.modal.isdisplay');
        toggleModal(modal.getAttribute('data-identifier'));
    })
}

document.onkeydown = function(evt) {
    evt = evt || window.event
    var isEscape = false
    if ("key" in evt) {
    isEscape = (evt.key === "Escape" || evt.key === "Esc")
    } else {
    isEscape = (evt.keyCode === 27)
    }
    if (isEscape && document.body.classList.contains('modal-active')) {
        //on cherche le modal ouvert
        let modal =  document.querySelector('.moda.isdisplay');
        toggleModal(modal.getAttribute('data-identifier'));
    }
};


function toggleModal (identifier, closeOtherModalFirst = false) {
    const body = document.querySelector('body');
    if(closeOtherModalFirst){
        if(document.body.classList.contains('modal-active')){
            var modalOuvertes = document.querySelectorAll('.isdisplay')
            modalOuvertes.forEach(function(c){
                toggleModal(c.getAttribute('data-identifier'), false);
            })
        }
    }
    var modal = document.querySelector('.moda[data-identifier="'+identifier+'"]')
    modal.classList.toggle('isdisplay')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
    body.classList.toggle('modal-active')
}


export {toggleModal};


